<template>
  <div>
    <div class="flex">
      <p class="font-barlow font-medium text-xl text-grey-medium-2">
        {{ title }} {{ displayVersionForSeller(productVersion) }} /
      </p>
      <p class="font-barlow font-bold text-xl text-grey-dark-2 ml-1">
        General Information
      </p>
      <div class="flex flex-grow justify-end">
        <Button
          text="Next"
          type="primary"
          :iconRight="arrowRight"
          size="medium"
          :onClick="goNext"
          data-testid="next-button"
        />
      </div>
    </div>
    <InfoBanner />
    <div class="bg-white shadow rounded px-2 pt-3 mt-3 pb-4.5 mb-4">
      <div class="max-w-1/2">
        <p class="font-barlow font-bold text-mdl text-grey-light mb-2">
          Assessment Details and Configuration
        </p>
        <p class="text-mdh text-grey-dark-1">
          Select an intuitive and distinctive name for this assessment so that
          you and your team can easily identify it among other past assessments.
        </p>
        <div class="px-2 mt-3">
          <InputField label="Assessment Name" placeholder="e.g. Mastery Model Assessment 2021" class="mb-3"
            :defaultValue="title" :onChange="setTitle" :maxlength="50"
            :disabled="mode === 'edit-launched' || mode === 'edit-started'" data-testid="name-field" />
          <p class="font-bold text-mdlh text-grey-dark-1 mb-2">Rules</p>
          <div class="px-3">
            <NewSelect
              label="Question Order"
              :options="questionOrderOptions"
              :value="questionOrder"
              :onSelect="setQuestionOrder"
              :disabled="true"
              :preselectFirst="false"
              class="mb-5"
              data-testid="question-order-dropdown"
            />
            <div class="flex items-start mb-3 cursor-pointer opacity-50">
              <div class="mt-0.4">
                <Checkbox :active="allowGoingBack" data-testid="answers-checkbox" />
              </div>
              <div class="ml-2">
                <p class="text-mdh text-grey-dark-1">
                  Allow going back and changing responses
                </p>
                <p class="text-sm-2-h text-grey-light">
                  Allow participants to change responses for previously answered
                  questions.
                </p>
              </div>
            </div>
            <div class="flex items-start mb-3 opacity-50">
              <div class="mt-0.4">
                <Checkbox :active="multipleSessions" data-testid="sessions-checkbox" />
              </div>
              <div class="ml-2">
                <p class="text-mdh text-grey-dark-1">
                  Allow completion in multiple sessions
                </p>
                <p class="text-sm-2-h text-grey-light">
                  Give participants the chance to take the assessments in more
                  than one single sit in.
                </p>
              </div>
            </div>
            <div class="flex flex-col justify-between mb-4 opacity-50" v-if="!multipleSessions">
              <p class="text-sm-2 text-grey-light font-medium mb-1">Duration</p>
              <p class="font-normal text-grey-dark-1 text-mdh">
                {{ timeAllocation }}
              </p>
            </div>
            <div v-if="organizationalAssessment" @click="changeRestriction" class="flex items-start mb-3 cursor-pointer">
              <div class="mt-0.4">
                <Checkbox
                  :active="restrictManagersResults"
                  data-testid="answers-checkbox"
                />
              </div>
              <div class="ml-2">
                <p class="text-mdh text-grey-dark-1">
                  Restrict Managers from filtering results
                </p>
                <p class="text-sm-2-h text-grey-light">
                  Restrict Managers from viewing results at a Team and
                  Participant level.
                </p>
              </div>
            </div>
            <NewSelect
              label="When to Publish Results for Employees"
              :options="publishResultsOptions"
              :disabled="mode === 'edit-launched' || mode === 'edit-started'"
              :value="publishResults"
              :onSelect="setPublishResults"
              :preselectFirst="false"
              data-testid="results-dropdown"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import debounce from "lodash/debounce";
import store from "@/store";
import moment from "moment";
import InputField from "../../../components/InputField/InputField.vue";
import NewSelect from "../../../components/Select/NewSelect.vue";
import Checkbox from "../../../components/Checkbox";
import arrowRight from "@/assets/img/icons/arrow-right-white.svg";
import InfoBanner from "../components/InfoBanner.vue";

export default {
  components: { InputField, NewSelect, Checkbox, InfoBanner },
  name: "GeneralInformation",
  data: () => ({
    arrowRight,
    assessmentTitle: "",
    questionOrderOptions: [
      { label: "Randomized" },
      { label: "Not Randomized" },
    ],
    questionOrder: null,
    restrictManagersResults: false,
    publishResultsOptions: [
      { label: "Upon assessment completion" },
      { label: "After admin publish" },
      { label: "Upon assessment close date" },
      /*   { label: "At a specific date" }, */
      { label: "Never" },
    ],
    publishResults: null,
    firstLoadForTime: true,
  }),
  computed: {
    ...mapState({
      assessmentSetup: (state) => state.assessmentSetup,
      id: (state) => state.assessmentSetup.cloneId,
      generalInfo: (state) => state.assessmentSetup.generalInfo,
      mode: (state) => state.assessmentSetup.mode,
    }),
    ...mapGetters({
      isImpersonating: "auth/isImpersonating",
      isSeller: "auth/isSeller",
      checkChanges: "assessmentSetup/getDirtyGeneralInfo",
    }),
    title: {
      get() {
        return this.assessmentSetup.assessmentTitle;
      },
      set(value) {
        this.setAssessmentTitle({ title: value });
      },
    },
    productVersion(){
      return this.generalInfo?.productVersion;
    },
    organizationalAssessment() {
      return this.generalInfo?.surveyType === 1;
    },
    disableNext() {
      return !this.title || this.title == "";
    },
    multipleSessions() {
      return this.generalInfo?.allowInMultipleSessions;
    },
    timeAllocation() {
      if (this.multipleSessions) {
        return;
      }
      const hours = this.generalInfo?.maxTimeAllocation.split(':')[0]
      const minutes = this.generalInfo?.maxTimeAllocation.split(':')[1]
      const hoursString = hours != '00' ? `${hours?.replace(/^0+/, '')} hours` : '';
      const minutesString = minutes != '00' ? `${minutes?.replace(/^0+/, '')} minutes` : '';
      const timeString = [hoursString, minutesString]
        .filter(x => x)
        .join(' and ')
      return `${timeString} time allocation`
    },
    allowGoingBack() {
      return this.generalInfo?.allowGoingBack;
    }
  },
  async mounted() {
    await this.getGeneralInfo({ id: this.id });
    this.restrictManagersResults = this.generalInfo.restrictManagersResults;
    this.title = this.generalInfo.name;
    this.questionOrder = this.generalInfo.hasRandomisedQuestions
      ? this.questionOrderOptions[0]
      : this.questionOrderOptions[1];
    this.publishResults = {
      label: this.parsePermissions(this.generalInfo.resultsPermission),
    };
  },
  methods: {
    ...mapActions({
      saveGeneralInfo: "assessmentSetup/saveGeneralInfo",
      setAssessmentTitle: "assessmentSetup/setAssessmentTitle",
      getGeneralInfo: "assessmentSetup/getGeneralInfo",
      showError: "alerts/showError",
      openModal: "modals/openModal",
    }),
    displayVersionForSeller(version) {
      if (version &&  version !== "" && (this.isImpersonating || this.isSeller)) 
        return " (v" + version + ")";
      return "";
    },
    changeRestriction() {
      return (this.restrictManagersResults = !this.restrictManagersResults);
    },
    setTitle: debounce(function (value) {
      this.title = value;
    }, 500),
    setQuestionOrder(val) {
      this.questionOrder = val;
    },
    setPublishResults(val) {
      this.publishResults = val;
    },
    async goNext() {
      this.save().then(() => {
        this.$router.push("/assessments/participants");
      });
    },
    async save() {
      try {
        const hasRandomisedQuestions = this.unparseRandomised(this.questionOrder);
        const resultsPermission = this.unparsePermissions(this.publishResults);
        await this.saveGeneralInfo({
          id: this.id,
          name: this.title,
          hasRandomisedQuestions: hasRandomisedQuestions,
          resultsPermission: resultsPermission,
          restrictManagersResults: this.restrictManagersResults,
        })
      } catch(err) {
        this.showError({
          messageHeader: "Error",
          message: err.message || "Error saving progress. Please try again in a few moments",
        });
        throw err;
      }
    },
    parsePermissions(item) {
      switch (item) {
        case 1:
          return "Never";
        case 2:
          return "Upon assessment completion";
        case 3:
          return "Upon assessment close date";
        case 4:
          return "After admin publish";
        default:
          return "Upon assessment completion";
      }
    },
    unparsePermissions(item) {
      switch (item.label) {
        case "Upon assessment close date":
          return 3;
        case "After admin publish":
          return 4;
        case "Upon assessment completion":
          return 2;
        case "Never":
          return 1;
        default:
          return 2;
      }
    },
    unparseRandomised(item) {
      switch (item.label) {
        case "Randomized":
          return true;
        case "Not Randomized":
          return false;
        default:
          return true;
      }
    },
  },
  async beforeRouteEnter(to, _, next) {
    const title = store.getters["assessmentSetup/getAssessmentTitle"];
    to.meta.sidenav.title = title + " Setup";
    next();
  },
  beforeRouteLeave(to, _, next) {
    const changeTitle = () =>
      this.setAssessmentTitle({ title: this.generalInfo.name });
    if (to.path === "/assessments/participants") next();
    else if (
      this.checkChanges({
        name: this.title,
        resultsPermission: this.unparsePermissions(this.publishResults),
      })
    )
      this.openModal({
        modal: "exitAssessmentModal",
        props: {
          next: next,
          save: this.save,
          path: to.path,
          noSaveHelper: changeTitle,
        },
      });
    else next();
  },
};
</script>
<style lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>